// components
const List = resolve => require(["@/components/admin/group_buying/_list.vue"], resolve)

export default {
    components: {
        List
    },
    computed: {
        componentType() {
            let validSubPages = [];
            switch (this.$route.params.type) {
                case 'list':
                    validSubPages = ['all'];
                    if (!validSubPages.includes(this.$route.params.subPage)) {
                        this.$router.push('/not_found');
                    }
                    return List;
            }
        },
    },
    created: function() {
        if (!this.$store.state.common.userInfo.adminPermission.group_buying || this.$store.state.common.userInfo.adminPermission.group_buying == 0) {
            this.$router.push('/admin/user/list/all');
        }
        this.$store.commit('common/setHeaderActiveLink', 'adminOrder');
        this.$store.commit('common/setHeaderChildrenActiveLink', 'adminGroupBuying');
    }
}
