import InfiniteScroll from 'vue-infinite-scroll'

// common
const Dialogue = resolve => require(["@/components/common/dialogue_client.vue"], resolve)

export default {
    props: {
        dialogueId: {
            required: true,
            type: String,
        },
        forumId: {
            required: true,
            type: String,
        },
        forumTypes: {
            required: true,
            type: Object,
        },
        onlyRead: {
            type: Boolean,
            default: false,
        },
        newPostContent: {
            type: Object,
            default: () => {},
        },
        selectedList: {
            required: true,
            type: String,
            validator: value => {
                return ['created', 'assigned', 'event'].includes(value)
            },
        },
    },
    directives: {
        InfiniteScroll,
    },
    components: {
        Dialogue,
    },
    data: function() {
        return {
            forumType: '',

            // p: post, c: comment
            allPIds: [],
            pIds: [],
            cIds: {},
            pInfos: {},
            addingPIds: {},
            updatingPIds: {},
            deletingPIds: {},
            editPId: '',  // pId or pId-cId
            editingPIds: {},
            deletePId: '',  // pId or pId-cId

            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個 index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢

            defaultId: {
                pDelete: 'deletePostDialogue',
                pNew: 'newPost',
            },

            isPostingApi: {
                updateForumType: false,  // 更新討論區留言權限
            },
        }
    },
    computed: {
        isPointerFine() {
            return this.$store.state.common.isPointerFine;
        },
        userInfo() {
            return this.$store.state.common.userInfo;
        },
    },
    created: function() {
        this.initForum();
    },
    methods: {
        async initForum() {
            if (!this.forumId) {
                return;
            }

            await this.getForumType();
            await new Promise(resolve => setTimeout(resolve, 500));
            this.getPostIds();

            // new post
            if (!this.onlyRead && !((this.selectedList !== 'created') && (this.forumType !== '0'))) {
                const content = {
                    text: this.newPostContent[this.forumId] ? this.newPostContent[this.forumId].text : '',
                };
                this.$set(this.editingPIds, this.defaultId.pNew, content);
                this.$nextTick(() => {
                    this.setTextboxHeight(document.getElementById(`${this.defaultId.pNew}-textbox`));
                });
            }
        },
        dialogueLoadedHandler() {
            // 關閉彈窗後
            $(`#${this.dialogueId}`).on('hidden.bs.modal', () => {
                let data = {};
                // 暫存新貼文的輸入內容
                if (this.editingPIds[this.defaultId.pNew]) {
                    const content = {
                        text: this.editingPIds[this.defaultId.pNew].text,
                    };
                    data[this.forumId] = content;
                }
                this.$emit('closeDialogue', data);
            });
        },

        getForumType() {
            return new Promise((resolve, reject) => {
                const params = {
                    forumGroupId: this.forumId,
                };

                this.$httpRequest.get('/api/forum/get_forum_permission', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            const result = response.data.result;

                            if (result) {
                                this.forumType = '0';  // 學生可以任意留言
                                if (!result.student.post) {
                                    if (result.student.reply) {
                                        this.forumType = '1';  // 學生只能回覆教師留言
                                    } else {
                                        this.forumType = '2';  // 學生只能觀看教師留言
                                    }
                                }
                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        updateForumType(type) {
            this.forumType = type;

            let params = {
                forumGroupId: this.forumId,
            };

            let canPost = true, canReply = true;
            switch (type) {
                case '1': {  // 學生只能回覆教師留言
                    canPost = false; canReply = true;
                    break;
                }
                case '2': {  // 學生只能觀看教師留言
                    canPost = false; canReply = false;
                    break;
                }
            }
            params.forumPermission = { student: { post: canPost, reply: canReply } };

            this.isPostingApi.updateForumType = true;

            this.$httpRequest.post('/api/forum/update_forum_permission', params)
                .then(response => {
                    this.isPostingApi.updateForumType = false;

                    if (response.data.state == 'OK') {
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                })
                .catch(error => {
                    this.isPostingApi.updateForumType = false;
                    console.error('Catched Error:', error);
                });
        },

        getPostIds() {
            const params = {
                forumGroupId: this.forumId,
            };

            this.$httpRequest.get('/api/forum/get_message_root_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        const result = response.data.result;

                        if (result) {
                            this.allPIds = result;
                            if (!this.allPIds.length) {
                                this.busy = false;
                                this.isGetAllList = true;
                            }
                            this.loadMore();
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        async loadMore() {
            if (this.isGetAllList) {
                return;
            }

            this.busy = true;
            let pIds = [];
            for (let i = 0; i < this.loadCountPerPage; i++) {
                let pId = this.allPIds[this.loadingIndex];
                if (!pId) {
                    this.isGetAllList = true;
                    break;
                }
                pIds.push(pId);
                this.loadingIndex++;
            }
            await this.getPostInfos(pIds);
            this.pIds = [...this.pIds, ...pIds];
            this.busy = false;
            this.getComments(pIds);
        },
        getPostInfos(pIds) {
            return new Promise((resolve, reject) => {
                if (!pIds.length) {
                    resolve();
                    return;
                }

                const params = {
                    postIds: pIds,
                };

                this.$httpRequest.post('/api/forum/get_message_infos', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            const result = response.data.result;

                            if (result) {
                                for (const pId of params.postIds) {
                                    const p = result[pId];

                                    if (!p) {
                                        continue;
                                    }

                                    const pInfo = {
                                        avatar: p.file_name,
                                        poster: p.name,
                                        postTime: this.$util.unixTimestampToDatetime(p.enterdate, this.$util.getBrowserCurrentTimeZone(), 'datetime', true),
                                        content: {
                                            text: p.content,
                                        },
                                        cIds: p.children,
                                        isPoster: p.is_mine,
                                    };
                                    this.$set(this.pInfos, pId, pInfo);
                                }
                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        async getComments(pIds) {
            let cIds = {};
            let promises = [];
            for (const pId of pIds) {
                const tempCIds = this.pInfos[pId].cIds;
                cIds[pId] = tempCIds;
                promises.push(this.getPostInfos(tempCIds));
            }
            await Promise.all(promises);
            // new comment
            if (!this.onlyRead && !((this.selectedList !== 'created') && (this.forumType === '2'))) {
                for (const pId of pIds) {
                    const newCId = `new_${pId}`;
                    cIds[pId] = [...cIds[pId], newCId];

                    const content = {
                        text: '',
                    };
                    this.$set(this.editingPIds, newCId, content);
                }
            }
            this.cIds = {...this.cIds, ...cIds};
        },
        addPost(pId) {
            // 未填寫任何內容
            if (Object.keys(this.editingPIds[pId]).every((key) => !this.editingPIds[pId][key].trim())) {
                return;
            }

            // 若為留言則應有貼文 Id
            const postId = pId.split('_')[1] ? pId.split('_')[1] : '';

            const params = {
                forumGroupId: this.forumId,
                postId: postId,
                content: this.editingPIds[pId].text,
            };

            this.$set(this.addingPIds, pId, 1);

            this.$httpRequest.post('/api/forum/post_message', params)
                .then(async (response) => {
                    if (response.data.state == 'OK') {
                        const result = response.data.result;

                        if (result) {
                            const newPId = result;
                            await this.getPostInfos([newPId]);
                            // 新增留言
                            if (postId) {
                                // 最後一則留言保留給新留言, 因此新增的留言插入在陣列的倒數第二個位置
                                this.cIds[postId].splice(this.cIds[postId].length - 1, 0, newPId);
                            }
                            // 新增貼文
                            else {
                                // 新貼文將新增於列表第一筆, 置頂卷軸以方便使用者查看
                                document.getElementById(`postsContainer`).scrollTo(0, 0);
                                await this.getComments([newPId]);
                                this.pIds.unshift(newPId);
                            }
                            this.$delete(this.addingPIds, pId);

                            // 初始貼文或留言內容
                            Object.keys(this.editingPIds[pId]).forEach((key) => this.editingPIds[pId][key] = '');
                            this.$nextTick(() => {
                                this.setTextboxHeight(document.getElementById(`${pId}-textbox`));
                            });
                        }
                    }
                    if (response.data.state == 'ERROR') {
                        this.$delete(this.addingPIds, pId);
                    }
                })
                .catch(error => {
                    this.$delete(this.addingPIds, pId);
                    console.error('Catched Error:', error);
                });
        },
        updatePost(pId) {
            // 未填寫任何內容
            if (Object.keys(this.editingPIds[pId]).every((key) => !this.editingPIds[pId][key].trim())) {
                return;
            }
            // 比對舊貼文或留言, 若內容皆相同則不需更新
            if (this.editingPIds[pId].text === this.pInfos[pId].content.text) {
                this.cancelPostEdit();
                return;
            }

            const params = {
                postId: pId,
                content: this.editingPIds[pId].text,
            };

            this.$set(this.updatingPIds, pId, 1);

            this.$httpRequest.post('/api/forum/update_message', params)
                .then(async (response) => {
                    if (response.data.state == 'OK') {
                        await this.getPostInfos([pId]);
                        this.$delete(this.updatingPIds, pId);
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        this.cancelPostEdit();
                    }
                    if (response.data.state == 'ERROR') {
                        this.$delete(this.updatingPIds, pId);
                    }
                })
                .catch(error => {
                    this.$delete(this.updatingPIds, pId);
                    console.error('Catched Error:', error);
                });
        },
        deletePost() {
            $(`#${this.defaultId.pDelete}`).modal('hide');

            const pId = this.deletePId.split('-')[0];
            const cId = this.deletePId.split('-')[1];

            const params = {
                postId: cId || pId,
            };

            this.$set(this.deletingPIds, params.postId, 1);

            this.$httpRequest.post('/api/forum/delete_message', params)
                .then(response => {
                    this.$delete(this.deletingPIds, params.postId);

                    if (response.data.state == 'OK') {
                        this.$delete(this.pInfos, params.postId);
                        if (cId) {
                            const foundIndex = this.cIds[pId].indexOf(cId);
                            this.$delete(this.cIds[pId], foundIndex);
                        } else {
                            const foundIndex = this.pIds.indexOf(pId);
                            this.$delete(this.pIds, foundIndex);
                        }
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                })
                .catch(error => {
                    this.$delete(this.deletingPIds, params.postId);
                    console.error('Catched Error:', error);
                });
        },

        savePost(pId) {
            if (isNaN(pId)) {
                this.cancelPostEdit();
                this.addPost(pId);
            } else {
                this.updatePost(pId);
            }
        },
        savePostOnKeydownHandler(e, pId) {
            if (this.isPointerFine && e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                this.savePost(pId);
            }
        },
        editPost(editPId) {
            this.cancelPostEdit();
            this.editPId = editPId;

            const pId = this.editPId.split('-')[0];
            const cId = this.editPId.split('-')[1];

            // 編輯留言
            if (cId) {
                const content = {
                    text: this.pInfos[cId].content.text,
                };
                this.$set(this.editingPIds, cId, content);
            }
            // 編輯貼文
            else {
                const content = {
                    text: this.pInfos[pId].content.text,
                };
                this.$set(this.editingPIds, pId, content);
            }

            this.$nextTick(() => {
                const elemId = cId ? `${cId}-textbox` : `${pId}-textbox`;
                const elem = document.getElementById(elemId);
                this.setTextboxHeight(elem);
                elem.focus();
            });
        },
        cancelPostEdit() {
            if (!this.editPId) {
                return;
            }

            const pId = this.editPId.split('-')[0];
            const cId = this.editPId.split('-')[1];

            if (cId) {
                this.$delete(this.editingPIds, cId);
            } else {
                this.$delete(this.editingPIds, pId);
            }

            this.editPId = '';
        },
        showDeletePostDialogue(deletePId) {
            this.cancelPostEdit();
            this.deletePId = deletePId;
            $(`#${this.defaultId.pDelete}`).modal('show');
        },

        setTextboxHeight(elem) {
            if (!elem) return;
            elem.style.height = '';
            elem.style.height = `${elem.scrollHeight}px`;
        },
        commentCardOnMouseleaveHandler() {
            $(".comment-more > .dropdown-menu").removeClass("show");
        },
    },
}
