import { mapGetters } from 'vuex'

export default {
    data: function() {
        return {
            oldArticleNotes: {},

            isSetDataReady: {
                getArticleNote: false,  // 取得文章筆記
            },
            isPostingApi: {
                updateArticleNote: false,  // 更新文章筆記
            }
        }
    },
    watch: {
        isNotesEdited(newValue) {
            this.isShowNotSavePrompt = newValue;
        },
        userInfo(newValue) {
            this.isSetDataReady.getArticleNote = false;

            // 已登入 -> 未登入
            if (this.$_.isEmpty(newValue)) {
                this.articleNotes = {};
            }

            this.getArticleNote();
        }
    },
    computed: {
        ...mapGetters({
            isShowCourseInfoBar: 'article/isShowCourseInfoBar'
        }),
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        articleNotes: {
            get() {
                return this.$store.state.article.articleNotes;
            },
            set(value) {
                this.$store.commit('article/setArticleNotes', value);
            }
        },
        isOpenNote: {
            get() {
                return this.$store.state.article.isOpenNote;
            },
            set(value) {
                this.$store.commit('article/setIsOpenNote', value);
            }
        },
        isScrollRightArticleContent: {
            get() {
                return this.$store.state.article.isScrollRightArticleContent;
            },
            set(value) {
                this.$store.commit('article/setIsScrollRightArticleContent', value);
            }
        },
        isShowNotSavePrompt: {
            get() {
                return this.$store.state.article.isShowNotSavePrompt;
            },
            set(value) {
                this.$store.commit('article/setIsShowNotSavePrompt', value);
            }
        },
        isNotesEdited() {
            if (!this.isSetDataReady.getArticleNote) {
                return;
            }

            return !this.$_.isEqual(this.articleNotes, this.oldArticleNotes);
        }
    },
    created: function() {
        this.getArticleNote();
    },
    mounted: function() {
        window.addEventListener('scroll', this.noteContentScrollHandler);
        window.addEventListener('resize', this.noteContentResizeHandler);
    },
    beforeDestroy: function() {
        window.removeEventListener('scroll', this.noteContentScrollHandler);
        window.removeEventListener('resize', this.noteContentResizeHandler);
    },
    methods: {
        noteContentScrollHandler() {
            let elemFooterHeight = $('.lms-footer').outerHeight();  // footer 高度

            let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;  // 取得 elem 因包含 overflow 而沒顯示在螢幕上的內容高度
            let windowHeight = $(window).innerHeight();  // 取得目前可視範圍高度
            let scrollTop = $(window).scrollTop(); // 取得卷軸目前的 Y座標 位置

            let courseInfoBar = this.isShowCourseInfoBar ? 80 : 0;

            if (windowHeight + scrollTop >= scrollHeight - elemFooterHeight) {
                let offset = (windowHeight + scrollTop) - (scrollHeight - elemFooterHeight);
                $('.note-wrapper .content-wrapper').css('height', `${windowHeight - offset - 180 - courseInfoBar}px`);
            } else {
                $('.note-wrapper .content-wrapper').css('height', `${windowHeight - 180 - courseInfoBar}px`);
            }
        },
        noteContentResizeHandler() {
            $('.note-wrapper .content-wrapper').css('height', '');
        },

        getArticleNote() {
            if (this.$_.isEmpty(this.userInfo)) {
                // 如果前端記憶體中沒有暫存筆記內容, 則初始化
                if (this.$_.isEmpty(this.articleNotes)) {
                    this.articleNotes = {
                        content: ''  // 內容
                    }
                }
                this.oldArticleNotes = this.$_.cloneDeep(this.articleNotes);

                this.isSetDataReady.getArticleNote = true;
                this.$nextTick(() => $('#noteContentInput').focus());

                return;
            }

            let params = {
                articleId: this.$route.params.id
            }

            this.$httpRequest.get('/api/article/get_notes', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            result.forEach(el => {
                                if (el.article_id === params.articleId) {
                                    this.articleNotes = {
                                        content: el.content  // 內容
                                    }
                                }
                            });
                            // 暫存原本的筆記資料, 以用來判斷是否顯示系統不自動儲存提示
                            this.oldArticleNotes = this.$_.cloneDeep(this.articleNotes);

                            this.isSetDataReady.getArticleNote = true;
                            this.$nextTick(() => $('#noteContentInput').focus());
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        async updateArticleNote() {
            // 如果使用者沒有編輯筆記, 則直接關閉筆記
            if (!this.isNotesEdited) {
                this.closeNote();
                return;
            }

            if (this.isPostingApi.updateArticleNote) {
                return;
            }

            let toastMsg = '';

            this.isPostingApi.updateArticleNote = true;

            // 已登入使用者, 更新筆記內容至 server
            if (!this.$_.isEmpty(this.userInfo)) {
                let payload = {
                    articleId: this.$route.params.id
                }
                toastMsg = await this.$store.dispatch('article/updateNoteToServer', payload);
            }
            // 未登入使用者, 暫存筆記內容在瀏覽器記憶體中
            else {
                toastMsg = '筆記更新成功';
            }

            this.isPostingApi.updateArticleNote = false;

            this.$store.dispatch('common/setAlert', { status: 'success', msg: toastMsg });
            this.isShowNotSavePrompt = false;
            this.closeNote();
        },
        closeNote() {
            this.isOpenNote = false;
            this.isScrollRightArticleContent = true;
        }
    }
}
