import InfiniteScroll from "vue-infinite-scroll"

export default {
    props: ['allCourseIds', 'sidebarList'],
    directives: {
        InfiniteScroll
    },
    data: function() {
        return {
            courseList: [],
            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    computed: {
        tableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "purchase_type",
                    itemText: "類型",
                    ownClass: "course-purchase-type",
                    isShow: true
                },
                {
                    itemName: "state",
                    itemText: "狀態",
                    ownClass: "course-state",
                    isShow: this.$route.params.subPage == 'all'
                },
                {
                    itemName: "id",
                    itemText: "課程ID",
                    ownClass: "course-id",
                    isShow: true
                },
                {
                    itemName: "issueNo",
                    itemText: "期數",
                    ownClass: "course-issue-no",
                    isShow: true
                },
                {
                    itemName: "name",
                    itemText: "課程名稱",
                    ownClass: "course-name",
                    isShow: true
                },
                {
                    itemName: "provider",
                    itemText: "內容供應",
                    ownClass: "course-provider",
                    isShow: true
                },
                {
                    itemName: "advanced",
                    itemText: "進階",
                    ownClass: "course-advanced",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
    },
    created: function() {
        if (this.allCourseIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getCourseList()
                    .then(() => this.busy = false)
                    .catch(error => console.log('catched error: ' + error));
            }
        },
        getCourseList() {
            return new Promise((resolve, reject) => {
                let courseIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempCourseId = this.allCourseIds[this.loadingIndex];
                    if (!tempCourseId) {
                        this.isGetAllList = true;
                        break;
                    }
                    courseIds.push(tempCourseId);
                    this.loadingIndex++;
                }

                if (courseIds.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    courseIds: courseIds
                }

                this.$httpRequest.post('/admin_api/course/get_course_list_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (!this.$_.isEmpty(result)) {
                                    courseIds.forEach(id => {
                                        let tempCourse = result[id];
                                        if (tempCourse) {
                                            this.courseList.push({
                                                id: tempCourse.id,
                                                issueNo: tempCourse.issue_no,
                                                purchaseType: tempCourse.purchase_type,
                                                state: tempCourse.state,
                                                name: tempCourse.name,
                                                provider: tempCourse.provider
                                            });
                                        }
                                    });
                                }

                                resolve();
                            } else {
                                reject("admin/course/list/_table_list.js: 'getCourseList' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("admin/course/list/_table_list.js: 'getCourseList' get error");
                        }
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                        reject("admin/course/list/_table_list.js: 'getCourseList' get error");
                    });
            });
        },
        setPurchaseTypeText(purchaseType) {
            switch (purchaseType) {
                case '0':
                    return '訂閱課程';
                case '1':
                    return '購買課程';
            }
        }
    }
}
