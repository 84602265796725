// components
const OrderItemList = resolve => require(["@/components/order/index/_order_item_list.vue"], resolve)
const OrderInfo = resolve => require(["@/components/order/index/_order_info.vue"], resolve)
const SelectCouponDialogue = resolve => require(["@/components/order/index/_select_coupon_dialogue.vue"], resolve)

export default {
    components: {
        OrderItemList,
        OrderInfo,
        SelectCouponDialogue
    },
    data: function() {
        return {
            courseBundleCases: {},  // 課程合購組合
            bundleCasesToPrompt: {},  // 需提示的課程合購組合

            isDeletingCourse: {},  // 是否正在刪除某個課程

            isSetDataReady: {
                getItems: false  // 取得商品列表
            },
            isPostingApi: {
                addItemsToCart: false,  // 新增商品至購物車
            }
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        orderUtil() {
            return this.$store.state.order.orderUtil;
        },
        orderItemsInfo: {
            get() {
                return this.$store.state.order.orderItemsInfo;
            },
            set(value) {
                this.$store.commit('order/setOrderItemsInfo', value);
            }
        },
        orderItemCourseIds: {
            get() {
                return this.$store.state.order.orderItemCourseIds;
            },
            set(value) {
                this.$store.commit('order/setOrderItemCourseIds', value);
            }
        },
        confirmSelectedCoupon: {
            get() {
                return this.$store.state.order.confirmSelectedCoupon;
            },
            set(value) {
                this.$store.commit('order/setConfirmSelectedCoupon', value);
            }
        },
        checkCouponCourseIds() {
            if (!this.isSetDataReady.getItems) {
                return [];
            }

            // 需排除合購方案的課程 ids
            let courseIds = [];

            for (let id of this.orderItemCourseIds) {
                if (!this.orderItemsInfo[id]) {
                    continue;
                }

                if (!this.orderItemsInfo[id].order.isFitBundleCase) {
                    courseIds.push(id);
                }
            }

            return courseIds;
        },
        isDisableUseCoupon() {
            if (!this.isSetDataReady.getItems) {
                return false;
            }

            // 若購物車內所有商品皆適用合購優惠組合, 則不得使用優惠券
            return !this.checkCouponCourseIds.length;
        },
        originalTotalPrice() {
            if (!this.isSetDataReady.getItems) {
                return 0;
            }

            let totalPrice = 0,
                noBundleTotalPrice = 0,
                quantity = 0;

            for (let id of this.orderItemCourseIds) {
                if (!this.orderItemsInfo[id]) {
                    continue;
                }

                totalPrice += (this.orderItemsInfo[id].order.unit * this.orderItemsInfo[id].order.price);
                // 把沒有合購的挑出
                if (!this.orderItemsInfo[id].order.isFitBundleCase) {
                    noBundleTotalPrice += (this.orderItemsInfo[id].order.unit * this.orderItemsInfo[id].order.price);
                }
                quantity += this.orderItemsInfo[id].order.unit;
            }

            let result = {
                price: totalPrice,
                noBundleTotal: noBundleTotalPrice,
                quantity: quantity
            };

            return result;
        },
        orderTotalPrice() {
            let totalPrice = this.originalTotalPrice.price,
                noBundleTotal = this.originalTotalPrice.noBundleTotal, // 合購價格
                discount = 0; // 折抵金額

            // 使用優惠券
            if (!this.$_.isEmpty(this.confirmSelectedCoupon)) {
                // 折扣金額
                if (this.confirmSelectedCoupon.discountType == '2') {
                    let discountPrice = Math.round(noBundleTotal * (this.confirmSelectedCoupon.discount / 100));
                    discount = noBundleTotal - discountPrice;
                    totalPrice = discountPrice + totalPrice - noBundleTotal;
                }
                // 折價金額
                else {
                    totalPrice = totalPrice - this.confirmSelectedCoupon.discount;
                }
                // 若折價後總計已小於 0 時, 則以 $0 顯示
                if (totalPrice < 0) {
                    totalPrice = 0;
                }
            }

            let result = {
                price: totalPrice,
                discount: discount
            }

            return result;
        }
    },
    created: function() {
        this.getData();
    },
    methods: {
        async getData() {
            this.orderItemCourseIds = await this.$store.dispatch('order/getCartItemCourseIds');

            this.courseBundleCases = await this.$store.dispatch('order/getCourseBundleCases', { courseIds: this.orderItemCourseIds });
            // 提示合購優惠組合給使用者
            for (let caseId in this.courseBundleCases) {
                if (this.courseBundleCases[caseId].isPromptToUser) {
                    this.bundleCasesToPrompt[caseId] = this.courseBundleCases[caseId];
                }
            }

            this.isSetDataReady.getItems = true;
        },
        async addBundleCoursesToCart(bundleCase, caseId) {
            $('.lms-btn.plain-text').blur();

            let courseIds = [];

            // 取得尚未加入購物車的課程 ids
            for (let el of bundleCase.courses) {
                if (!this.orderItemCourseIds.includes(el.id)) {
                    courseIds.push(el.id);
                }
            }

            // 將課程加入購物車
            this.isPostingApi.addItemsToCart = true;
            await this.$store.dispatch('order/addItemsToCart', { courseIds: courseIds });
            this.isPostingApi.addItemsToCart = false;
            this.orderItemCourseIds = this.orderItemCourseIds.concat(courseIds);

            // 顯示合購折扣後的價錢
            for (let el of bundleCase.courses) {
                if (!this.orderItemsInfo[el.id]) {
                    continue;
                }

                // 價錢旁邊顯示 '合購優惠'
                this.orderItemsInfo[el.id].order.isFitBundleCase = true;
                // 目前顯示的價錢改為合購優惠價錢
                this.orderItemsInfo[el.id].order.price = +el.discountPrice;
                // 記錄課程對應到的合購課程組合 id
                this.orderItemsInfo[el.id].order.bundleCaseId = caseId;
            }

            // 目前合購優惠組合已不需提示使用者
            this.$delete(this.bundleCasesToPrompt, caseId);

            // 清空已選擇的優惠券
            this.confirmSelectedCoupon = {};
        },
        async deleteItemFromCart(item, index) {
            // 從購物車刪除課程
            this.$set(this.isDeletingCourse, item.course.id, true);
            await this.$store.dispatch('order/deleteItemFromCart', { courseId: item.course.id });
            this.$set(this.isDeletingCourse, item.course.id, false);

            // 若刪除的課程為合購課程組合內的課程, 則課程顯示價格需改回原本售價
            if (this.courseBundleCases[item.order.bundleCaseId]) {
                let tempBundleCase = this.courseBundleCases[item.order.bundleCaseId];
                let courseCount = 0;  // 目前訂單內該合購優惠組合的課程數量

                for (let el of tempBundleCase.courses) {
                    if (!this.orderItemsInfo[el.id]) {
                        continue;
                    }

                    courseCount++;

                    // 價錢旁邊隱藏 '合購優惠'
                    this.orderItemsInfo[el.id].order.isFitBundleCase = false;
                    // 目前顯示的價錢改成 'originalPrice'
                    this.orderItemsInfo[el.id].order.price = +this.orderItemsInfo[el.id].order.originalPrice;
                }

                // 目前訂單內符合合購優惠組合的課程數量僅剩 1個, 表示刪除此課程後即可不需提示此合購優惠組合
                if (courseCount === 1) {
                    // 從合購優惠組合列表中刪除目前合購組合
                    this.$delete(this.courseBundleCases, item.order.bundleCaseId);
                    this.$delete(this.bundleCasesToPrompt, item.order.bundleCaseId);
                } else if (courseCount > 1) {
                    // 提示合購優惠組合給使用者
                    this.courseBundleCases[item.order.bundleCaseId].isPromptToUser = true;
                    this.bundleCasesToPrompt[item.order.bundleCaseId] = this.courseBundleCases[item.order.bundleCaseId];
                }
            }

            // 從顯示的商品列表中刪除課程
            this.$delete(this.orderItemsInfo, item.course.id);
            this.$delete(this.orderItemCourseIds, index);

            // 清空已選擇的優惠券
            this.confirmSelectedCoupon = {};
        }
    }
}
