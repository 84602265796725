// components
const List = resolve => require(["@/components/admin/article/_list.vue"], resolve)
const Edit = resolve => require(["@/components/admin/article/_edit.vue"], resolve)

export default {
    components: {
        List,
        Edit
    },
    computed: {
        componentType() {
            let validSubPages = [];
            switch (this.$route.params.type) {
                case 'list':
                    validSubPages = ['all', 'editing', 'auditing', 'scheduled', 'publishing', 'archieved'];
                    if (!validSubPages.includes(this.$route.params.subPage)) {
                        this.$router.push('/not_found');
                    }
                    return List;
                case 'edit':
                    validSubPages = ['info', 'article', 'practice', 'editors', 'remove_settings', 'statistics'];
                    if (!validSubPages.includes(this.$route.params.subPage)) {
                        this.$router.push('/not_found');
                    }
                    return Edit;
            }
        },
    },
    created: function() {
    }
}
