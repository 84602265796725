// common
const MobileUnsupported = resolve => require(["@/components/common/mobile_unsupported.vue"], resolve)
// components
const ArticleList = resolve => require(["@/components/user/favorite/_article_list.vue"], resolve)

export default {
    components: {
        MobileUnsupported,
        ArticleList,
    },
    data: function() {
        return {
            favoriteList: [],
            oldFavoriteList: [],
            isShowAddClassification: false,
            inputNewClassificationName: '',
            isShowAddFolder: false,
            inputNewFolderName: '',
            isShowFavoriteListEdit: false,
            isShowEditName: false, // 目前是否有在編輯名稱
            folderCheckedids: [],
            selectedFolderId: 0,

            allMyArticleIds: [], // 文章
            renderArticleListCount: 0,
            articleCount: 0,

            isSetDataReady: {
                getInitialData: false,  // 取得我的最愛資料夾結構
            }
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        isShowDeleteArticles: {
            get() {
                return this.$store.state.user.isShowDeleteArticles;
            },
            set(value) {
                this.$store.commit('user/setShowDeleteArticles', value);
            }
        },
        isDisableOtherBtn: { // 用於_article_list判斷是否要disable其它按鈕
            get() {
                return this.$store.state.user.isDisableOtherBtn;
            },
            set(value) {
                this.$store.commit('user/setDisableOtherBtn', value);
            }
        },
        disableBtn() { // 用於此favorite.js判斷是否要disable其它按鈕
            if (this.isShowAddClassification
                || this.isShowAddFolder
                || this.isShowFavoriteListEdit
                || this.isShowEditName
                || this.isShowDeleteArticles) {
                    this.isDisableOtherBtn = true;
                    return true;
            }
            this.isDisableOtherBtn = false;
            return false;
        }
    },
    created: function() {
        this.$store.commit('common/setHeaderActiveLink', 'userFavorite');
        this.getInitialData();
    },
    methods: {
        async getInitialData() {
            this.isSetDataReady.getInitialData = false;
            this.favoriteList = [];
            await this.getFavoriteList();

            this.isSetDataReady.getInitialData = true;
        },
        getFavoriteList() {
            return new Promise((resolve, reject) => {
                this.$httpRequest.get('/api/bookmark/get_folders')
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                for(let item of result) {
                                    let children = [];
                                    if (item.folders.length > 0) {
                                        for(let child of item.folders) {
                                            children.push({
                                                id: child.id,
                                                name: child.name,
                                                no: child.no,
                                                count: child.count,
                                                isShowNameEdit: false
                                            })
                                        }
                                    }
                                    this.favoriteList.push({
                                        id: item.id,
                                        name: item.name,
                                        no: item.no,
                                        isChecked: false,
                                        isShowNameEdit: false,
                                        children: children
                                    })
                                }

                                if (this.favoriteList.length > 0) {
                                    this.oldFavoriteList = this.$_.cloneDeep(this.favoriteList);
                                }
                                let folderId = this.$route.query.f ? this.$route.query.f : 0;
                                if (folderId != 0) { // 由於所有收藏的文章數輛是到前端才計算的，為了文章新增刪除也能更新數量，所以都要{所有收藏ID=0}再call一次getArticleList
                                    this.getArticleList(0);
                                }
                                this.getArticleList(folderId);
                                resolve();
                            }
                        }
                    })
                    .catch(error => {
                        reject('catched error: ' + error);
                    });
            });
        },
        checkChildren(index) {
            this.favoriteList[index].isChecked = !this.favoriteList[index].isChecked;
            if (this.favoriteList[index].isChecked) {
                for (let item of this.favoriteList[index].children) {
                    if (!this.folderCheckedids.find(id => id == item.id)) {
                        this.folderCheckedids.push(item.id);
                    }
                }
            }
            else {
                for (let item of this.favoriteList[index].children) {
                    let deleteIndex = this.folderCheckedids.findIndex(id => id == item.id);
                    if (deleteIndex > -1) {
                        this.folderCheckedids.splice(deleteIndex, 1);
                    }
                }
            }
        },
        showAddClassification() {
            this.isShowAddClassification = true;
            this.inputNewClassificationName = '';
            setTimeout(() => { // 等dom長出來再focus
                this.$refs.addClassificationInput.focus();
            }, 10);
        },
        addClassification() {
            let params = {
                name: this.inputNewClassificationName
            }

            this.$httpRequest.post('/api/bookmark/add_category', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.favoriteList.unshift({
                                id: result,
                                name: this.inputNewClassificationName,
                                isShowNameEdit: false,
                                children: []
                            });
                            this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        }
                    }
                    this.isShowAddClassification = false;
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        showAddFolder(index) {
            if (this.favoriteList[index].children.length > 0 && this.favoriteList[index].children[0].id == '') {
                return;
            }
            this.favoriteList[index].children.unshift({
                id: '',
                name: '',
                count: 0,
                isChecked: false
            })
            this.isShowAddFolder = true;
            this.inputNewFolderName = '';
            setTimeout(() => { // 等dom長出來再focus
                let name = 'addFolderInput' + index;
                this.$refs[name][0].focus();
            }, 10);
        },
        cancelAddFolder(index, key) {
            this.isShowAddFolder = false;
            if (this.favoriteList[index].children[key].id == '') {
                this.favoriteList[index].children.splice(0, 1);
            }
        },
        addFolder(index, key) {
            let params = {
                name: this.inputNewFolderName,
                categoryId: this.favoriteList[index].id
            }

            this.$httpRequest.post('/api/bookmark/add_folder', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.favoriteList[index].children[key] = {
                                id: result,
                                name: this.inputNewFolderName,
                                count: 0,
                                isChecked: false
                            };
                            this.isShowAddFolder = false;
                            this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        }
                    }
                    if (response.data.state == 'ERROR') {
                        this.cancelAddFolder(index, key);
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        showFavoriteListEdit() {
            this.folderCheckedids = [];
            for (let item of this.favoriteList) {
                item.isChecked = false;
            }
            this.isShowFavoriteListEdit = true;
        },
        deleteFolders() {
            let params = {
                folderIds: this.folderCheckedids
            }

            this.$httpRequest.post('/api/bookmark/delete_folders', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let isSelectedFolderId = false, // 目前刪除的id中，是否有剛好selected的資料夾
                            checkSelectIds = []; // 存放要檢查的id
                        for (let i = this.favoriteList.length-1; i >=0; i-- ) {
                            let childIds = [];
                            for (let j = this.favoriteList[i].children.length-1; j >=0; j--) {
                                childIds.push(this.favoriteList[i].children[j].id);
                                if (this.folderCheckedids.find(el => el == this.favoriteList[i].children[j].id)) {
                                    this.favoriteList[i].children.splice(j, 1);
                                }
                            }
                            if (this.folderCheckedids.find(el => el == this.favoriteList[i].id)) {
                                checkSelectIds.push(this.favoriteList[i].id);
                                checkSelectIds = checkSelectIds.concat(childIds)
                                this.favoriteList.splice(i, 1);
                            }
                        }

                        if (checkSelectIds.find(el => el == this.selectedFolderId)) {
                            isSelectedFolderId = true;
                        }

                        this.folderCheckedids = [];
                        this.isShowFavoriteListEdit = false;
                        if (isSelectedFolderId) {

                            if (this.favoriteList.length > 0 && this.favoriteList[0].children.length > 0) {
                                this.selectedFolderId = this.favoriteList[0].children[0].id;
                                this.getArticleList(this.selectedFolderId);
                            }
                            else {
                                this.selectedFolderId = 0;
                                this.allMyArticleIds = [];
                                this.renderArticleListCount++;
                            }
                        }
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        showEditClassificationInput(index) {
            this.favoriteList[index].isShowNameEdit = true;
            this.isShowEditName = true;
            let name = 'editClassificationInput' + index;
            setTimeout(() => { // 等dom長出來再focus
                this.$refs[name][0].focus();
            }, 10);
        },
        saveClassificationName(index) {
            this.favoriteList[index].isShowNameEdit = false;
            this.isShowEditName = false;
            if (this.favoriteList[index].name == this.oldFavoriteList[index].name) {
                return;
            }

            let folders = [];
            folders.push({
                id: this.favoriteList[index].id,
                name: this.favoriteList[index].name
            });

            let params = {
                folders: folders
            }

            this.$httpRequest.post('/api/bookmark/update_folders', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        copyClassification(index) {
            let newItem = this.$_.cloneDeep(this.favoriteList[index]),
                params = {
                    categoryId: newItem.id // 目前id還是被複製對象的id
                }
                newItem.name = newItem.name + '-複製';

            this.$httpRequest.post('/api/bookmark/copy_category', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            newItem.id = result.categoryId; // 此id已被改成新複製的id
                            if (result.folderIds.length > 0 && newItem.children.length > 0 &&
                                result.folderIds.length == newItem.children.length) {
                                    newItem.children.forEach((el, index) => {
                                        el.id = result.folderIds[index];
                                    })
                            }
                            this.favoriteList.unshift(newItem); // 項目插到第一個
                            this.oldFavoriteList = this.$_.cloneDeep(this.favoriteList);
                            this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        showEditFolderInput(index, key) {
            this.favoriteList[index].children[key].isShowNameEdit = true;
            this.isShowEditName = true;
            let name = 'editFolderInput' + index + key;
            setTimeout(() => { // 等dom長出來再focus
                this.$refs[name][0].focus();
            }, 10);
        },
        saveFolderName(index, key) {
            this.favoriteList[index].children[key].isShowNameEdit = false;
            this.isShowEditName = false;
            if (this.favoriteList[index].children[key].name == this.oldFavoriteList[index].children[key].name) {
                return;
            }

            let folders = [];
            folders.push({
                id: this.favoriteList[index].children[key].id,
                name: this.favoriteList[index].children[key].name
            });

            let params = {
                folders: folders
            }

            this.$httpRequest.post('/api/bookmark/update_folders', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        copyFolder(index, key) {
            let newItem = this.$_.cloneDeep(this.favoriteList[index].children[key]),
                params = {
                    folderId: newItem.id // 目前id還是被複製對象的id
                }
                newItem.name = newItem.name + '-複製';

            this.$httpRequest.post('/api/bookmark/copy_folder', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            newItem.id = result; // 此id已被改成新複製的id
                            this.favoriteList[index].children.unshift(newItem); // 項目插到第一個
                            this.oldFavoriteList = this.$_.cloneDeep(this.favoriteList);
                            this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        } else {
                            console.log("user/read/_favorite_article_dialogue.js: 'copyFolder' get error");
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        getArticleList(folderId) {
            if (folderId < 0 || this.isShowFavoriteListEdit) { // 資料樹為空的時候或處於分類或資料夾編輯模式時
                return;
            }
            this.selectedFolderId = folderId;

            this.allMyArticleIds = [];
            let params = {
                    folderId: this.selectedFolderId
                }

            this.$httpRequest.post('/api/bookmark/get_bookmark_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            // 當點擊不同分類才需要在網址後補上參數，重新整理不會進入
                            if (this.$route.query.f != folderId) {
                                this.$router.push({ query: { f: folderId }});
                            }
                            this.allMyArticleIds = result;
                            this.isShowDeleteArticles = false; //當文章重載，必須初始這個參數
                            this.renderArticleListCount++;
                            // 因為所有收藏的數量不會出現在get_folder，所以要額外補一個給他
                            if (folderId == 0) {
                                this.articleCount = result.length;
                            }
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        deleteArticleIds(id) {
            // 如果是所有收藏資料夾刪除文章，只能重call api來達到更新資料夾文章數量
            if (this.selectedFolderId == 0) {
                this.favoriteList = [];
                this.getFavoriteList();
            } else {
                if (this.allMyArticleIds.length > 0) {
                    let deleteIndex = this.allMyArticleIds.findIndex(item => item == id);
                    this.allMyArticleIds.splice(deleteIndex, 1); // 當右邊文章刪除文章後，左邊資料夾的allMyArticleIds也要刪除文章id
                }
                for (let item of this.favoriteList) {
                    for (let child of item.children) {
                        if (this.selectedFolderId == child.id) {
                            child.count = this.allMyArticleIds.length;
                        }
                    }
                }
            }
        }
    }
}
