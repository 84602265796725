// components
const all = resolve => require(["@/components/admin/data_log/main.vue"], resolve)

export default {
    beforeCreate: function() {
        // 使用者權限非 admin 時, 則導向至後台 '課程列表' 頁面
        if (!this.$_.isEmpty(this.$store.state.common.userInfo) &&
            this.$store.state.common.userInfo.permission !== 'admin') {
            this.$router.push('/admin/course/list/all').catch(() => {});
        }
    },
    components: {
        all
    },
    computed: {
        componentType() {
            let validSubPages = ['all'];
            if (!validSubPages.includes(this.$route.params.subPage)) {
                this.$router.push('/not_found').catch(() => {});
            }

            let component = this.$route.params.subPage;

            // return componet
            return component;
        },
    },
    created: function() {
        this.$store.commit('common/setHeaderActiveLink', 'adminDataLog');
    },
}
