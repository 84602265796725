import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/scss/index.scss'
// common
import Dialogue from "@/components/common/dialogue.vue"
// components
const CourseTableList = resolve => require(["@/components/admin/push_notification/common/_course_table_list.vue"], resolve)

export default {
    components: {
        DatePicker,
        Dialogue,
        CourseTableList
    },
    data: function() {
        return {
            bannerInfo: {},
            oldBannerInfo: {},
            updatingInfo: {},  // 編輯橫幅有更動的項目

            inputSearchCourseText: '',  // 搜尋輸入的課程 id 或 名稱
            oldInputSearchText: '',
            allCourseIds: [],
            courseGroups: {},

            uploadErrorMsg: {
                image: '',
            },

            renderTableListKey: 0,

            isSettingImagePreviewer: false,  // 是否正在設置橫幅圖片預覽器
            isSetDataReady: {
                getBannerInfo: false,  // 取得橫幅資訊
                getCourseGroups: false,  // 取得系列課程列表
            },
            isPostingApi: {
                saveBanner: false,  // 新增/編輯橫幅資訊
            }
        }
    },
    watch: {
        'editingBannerInfo.id'(newValue) {
            if (!this.$_.isNull(newValue)) {
                this.getBannerInfo();
            }
        },
        linkedResourceInfo(newValue) {
            if (!this.$_.isEmpty(newValue)) {
                this.bannerInfo.linkedResource.id = newValue.id;
                this.bannerInfo.linkedResource.name = newValue.name;
                this.linkedResourceInfo = {};
            }
        }
    },
    computed: {
        newScheduledItem: {
            get() {
                return this.$store.state.adminPnBanner.newScheduledItem;
            },
            set(value) {
                this.$store.commit('adminPnBanner/setNewScheduledItem', value);
            }
        },
        editedPublishedItem: {
            get() {
                return this.$store.state.adminPnBanner.editedPublishedItem;
            },
            set(value) {
                this.$store.commit('adminPnBanner/setEditedPublishedItem', value);
            }
        },
        editedScheduledItem: {
            get() {
                return this.$store.state.adminPnBanner.editedScheduledItem;
            },
            set(value) {
                this.$store.commit('adminPnBanner/setEditedScheduledItem', value);
            }
        },
        editingBannerInfo: {
            get() {
                return this.$store.state.adminPnBanner.editingBannerInfo;
            },
            set(value) {
                this.$store.commit('adminPnBanner/setEditingBannerInfo', value);
            }
        },
        linkedResourceInfo: {
            get() {
                return this.$store.state.adminPnBanner.linkedResourceInfo;
            },
            set(value) {
                this.$store.commit('adminPnBanner/setLinkedResourceInfo', value);
            }
        },
        isUpdatingBanner() {  // 是否為編輯橫幅
            return !this.$_.isNull(this.editingBannerInfo.id) && this.editingBannerInfo.id !== '0';
        },
        isEditable() {
            // 是否可以編輯橫幅資料項目, 條件如下:
            // 1. 新增橫幅
            // 2. 編輯 '排程中' 橫幅
            return !this.isUpdatingBanner ||
                    (this.isUpdatingBanner && this.editingBannerInfo.state === 'scheduled');
        },
        linkedResTypeOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '無'
                },
                {
                    value: '1',
                    text: '產品介紹'
                },
                {
                    value: '2',
                    text: '單一課程'
                },
                {
                    value: '4',
                    text: '系列課程'
                },
                {
                    value: '3',
                    text: '外部連結'
                }
            ]
            return opts;
        },
        isHasDeadlineList() {
            let opts = [];
            opts = [
                {
                    value: true,
                    text: '限期'
                },
                {
                    value: false,
                    text: '不限期'
                }
            ]
            return opts;
        },
        isDisableSaveBanner() {
            // 編輯橫幅, 檢查是否有更動項目
            if (this.isUpdatingBanner && this.isSetDataReady.getBannerInfo) {
                let excludeKeys = ['image', 'linkedResource', 'isDeviceLink', 'isHasDeadline'];

                // 適用檢查物件第一層 keys 差異
                this.updatingInfo = this.$_.omitBy(this.$_.omit(this.bannerInfo, excludeKeys), (v, k) => {
                    return this.oldBannerInfo[k] == v;
                });

                // 橫幅圖片
                if (this.bannerInfo.image.url !== this.oldBannerInfo.image.url) {
                    this.updatingInfo.image = {
                        file: this.bannerInfo.image.file
                    }
                }

                // 連結項目
                if (this.bannerInfo.linkedResource.type !== this.oldBannerInfo.linkedResource.type) {
                    this.updatingInfo.linkedResource = {
                        type: this.bannerInfo.linkedResource.type
                    }
                }
                // 連結項目為 '單一課程' 或 '系列課程' 並且有更換 resourceId 時, 則需帶 resourceId
                if (['2', '4'].includes(this.bannerInfo.linkedResource.type) &&
                    (this.bannerInfo.linkedResource.id !== this.oldBannerInfo.linkedResource.id)) {
                    if (!this.$_.isEmpty(this.updatingInfo.linkedResource)) {
                        this.updatingInfo.linkedResource.id = this.bannerInfo.linkedResource.id;
                    } else {
                        this.updatingInfo.linkedResource = {
                            id: this.bannerInfo.linkedResource.id
                        }
                    }
                }
                // 連結項目為 '外部連結' 並且有更換 resourceUrl 時, 則需帶 resourceUrl
                if (this.bannerInfo.linkedResource.type === '3' &&
                    (this.bannerInfo.linkedResource.url !== this.oldBannerInfo.linkedResource.url)) {
                    if (!this.$_.isEmpty(this.updatingInfo.linkedResource)) {
                        this.updatingInfo.linkedResource.url = this.bannerInfo.linkedResource.url;
                    } else {
                        this.updatingInfo.linkedResource = {
                            url: this.bannerInfo.linkedResource.url
                        }
                    }
                }

                // 載具導流
                if (this.bannerInfo.linkedResource.type !== '0') {
                    let deviceUpdatingInfo = this.$_.omitBy(this.bannerInfo.isDeviceLink, (v, k) => {
                        return this.oldBannerInfo.isDeviceLink[k] == v;
                    });

                    if (!this.$_.isEmpty(deviceUpdatingInfo)) {
                        this.updatingInfo.isDeviceLink = this.bannerInfo.isDeviceLink;
                    } else {
                        this.$delete(this.updatingInfo, 'isDeviceLink');
                    }
                }
            }

            // 所有項目皆必填
            // 名稱
            if (!this.bannerInfo.name || /^\s*$/.test(this.bannerInfo.name)) {
                return true;
            }

            // 橫幅圖片
            if (!this.bannerInfo.image.url) {
                return true;
            }

            // 連結項目
            // 單一課程、系列課程
            if (['2', '4'].includes(this.bannerInfo.linkedResource.type) && !this.bannerInfo.linkedResource.id) {
                return true;
            }
            // 外部連結
            if (this.bannerInfo.linkedResource.type === '3' && !this.bannerInfo.linkedResource.url) {
                return true;
            }

            // 載具導流
            // 若有連結項目, 則至少選一種載具
            if (this.bannerInfo.linkedResource.type !== '0' && this.$_.values(this.bannerInfo.isDeviceLink).every(el => el === false)) {
                return true;
            }

            // 起始時間 & 結束時間(限期)
            if (!this.bannerInfo.publishStart ||
                (this.bannerInfo.isHasDeadline && !this.bannerInfo.publishEnd)) {
                return true;
            }

            // 編輯橫幅時如果沒有更新項目, 則 disable 儲存按鈕
            if (this.isUpdatingBanner) {
                return this.$_.isEmpty(this.updatingInfo);
            }

            return false;
        }
    },
    methods: {
        dialogueLoadedHandler() {
            // 開啟新增/編輯橫幅彈窗後
            $('#editBannerDialogue').on('shown.bs.modal', () => {
                if (!this.isUpdatingBanner) {
                    document.getElementById('bannerNameInput').focus();
                }
                this.resetSettings();
            });

            // 關閉新增/編輯橫幅彈窗後
            $('#editBannerDialogue').on('hidden.bs.modal', () => {
                this.editingBannerInfo = this.$_.mapValues(this.editingBannerInfo, () => null);
                this.bannerInfo = {};
                this.oldBannerInfo = {};
                this.uploadErrorMsg = this.$_.mapValues(this.uploadErrorMsg, () => '');
                this.isSetDataReady.getBannerInfo = false;
            });
        },
        resetSettings() {
            // 初始輸入框內容

            // 課程
            this.inputSearchCourseText = '';
            this.oldInputSearchText = '';
            this.allCourseIds = [];
        },

        setDefaultInfo() {
            this.bannerInfo = {
                name: '',
                image: {
                    name: null,
                    url: null,
                    file: null
                },
                linkedResource: {  // 連結項目
                    type: '0',  // 0: 無 ; 1: 產品介紹 ; 2: 單一課程 ; 3: 外部連結 ; 4: 系列課程
                    id: '',
                    name: '',
                    url: ''
                },
                isDeviceLink: {  // 各載具是否點擊橫幅導流至 '連結項目' 的對應頁面
                    Web: true,
                    iOS: true,
                    Android: true
                },
                isUpdateMaintain: false,
                isHasDeadline: true,
                publishStart: null,
                publishEnd: null
            }
        },
        getBannerInfo() {
            // 新增橫幅, 初始橫幅資訊內容
            if (this.editingBannerInfo.id === '0') {
                this.setDefaultInfo();
                this.isSetDataReady.getBannerInfo = true;
                return;
            }

            let params = {
                bannerId: this.editingBannerInfo.id
            }

            this.$httpRequest.get('/admin_api/banner/get_banner_detail', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.bannerInfo = {
                                name: result.name,
                                image: {
                                    name: result.image.original_name,
                                    url: result.image.file_url,
                                    file: null
                                },
                                linkedResource: result.resource,
                                isDeviceLink: result.is_device_link,
                                isUpdateMaintain: result.is_update_maintain,
                                isHasDeadline: !this.$_.isNull(result.end_date),
                                publishStart: this.$util.unixTimestampToDatetime(result.start_date, this.$util.getBrowserCurrentTimeZone(), 'datetime', true),
                                publishEnd: result.end_date ? this.$util.unixTimestampToDatetime(result.end_date, this.$util.getBrowserCurrentTimeZone(), 'datetime', true) : null
                            }

                            // 選擇 '系列課程' 類型時, 則先取得所有可選擇的系列課程
                            if (this.bannerInfo.linkedResource.type === '4') {
                                this.getCourseGroups();
                            }

                            // 暫存原本的橫幅資訊, 以用來判斷是否 enable 儲存按鈕
                            this.oldBannerInfo = this.$_.cloneDeep(this.bannerInfo);

                            this.isSetDataReady.getBannerInfo = true;
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },

        uploadImage() {
            this.uploadErrorMsg.image = '';

            let tempUploadFile = this.$refs.uploadImage.files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['jpg', 'jpeg', 'png', 'gif'];
            let perFileLimitSize = 1048576;  // 1mb

            this.uploadErrorMsg.image = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.image && tempUploadFile.type.match(/^image\//)) {
                this.bannerInfo.image.name = tempUploadFile.name;
                this.bannerInfo.image.file = tempUploadFile;

                this.isSettingImagePreviewer = true;

                let reader = new FileReader();
                reader.readAsDataURL(tempUploadFile);
                reader.onload = () => {
                    if (tempUploadFile.type.match(/gif/)) {
                        this.bannerInfo.image.url = reader.result;
                        this.isSettingImagePreviewer = false;
                        return;
                    }

                    let img = document.createElement("img");
                    img.onload = () => {
                        let width = 2016;
                        let height = 1134;

                        let canvas = document.createElement("canvas");
                        canvas.width = width;
                        canvas.height = height;

                        let ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0, width, height);

                        let dataurl = canvas.toDataURL(tempUploadFile.type);
                        this.bannerInfo.image.url = dataurl;

                        this.isSettingImagePreviewer = false;
                    }
                    img.src = reader.result;  // e.target.result
                }
            }

            this.$refs.uploadImage.value = null;
        },
        changeLinkedResType() {
            this.deleteLinkedResource();

            // {課程, ...} -> 無 , 初始 '載具連結' 各載具的布林值
            if (this.bannerInfo.linkedResource.type === '0') {
                this.bannerInfo.isDeviceLink = this.$_.mapValues(this.bannerInfo.isDeviceLink, () => true);
            }
            // 取得系列課程列表
            if (this.bannerInfo.linkedResource.type === '4') {
                this.getCourseGroups();
            }
        },
        deleteLinkedResource() {
            this.bannerInfo.linkedResource.id = '';
            this.bannerInfo.linkedResource.name = '';
            this.bannerInfo.linkedResource.url = '';
            this.resetSettings();
            this.focusInput();
        },
        focusInput() {
            let inputDOM = '';  // 搜尋輸入框 DOM
            switch (this.bannerInfo.linkedResource.type) {
                case '2':  // 課程
                    inputDOM = 'searchCourseTextInput';
                    break;
                case '3':  // 外部連結
                    inputDOM = 'externalUrlInput';
                    break;
            }
            this.$nextTick(() => inputDOM && document.getElementById(inputDOM).focus());
        },
        getCourseIdsBySearchText() {
            if (!this.inputSearchCourseText || /^\s*$/.test(this.inputSearchCourseText)) {
                return;
            }
            if (this.inputSearchCourseText === this.oldInputSearchText) {
                return;
            }

            this.oldInputSearchText = this.inputSearchCourseText;

            let params = {
                state: '-1',
                key: this.inputSearchCourseText
            }

            this.allCourseIds = [];

            this.$httpRequest.get('/admin_api/course/get_course_ids_by_search', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allCourseIds = result;

                            this.renderTableListKey++;
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        getCourseGroups() {
            // 已讀取過資料
            if (this.isSetDataReady.getCourseGroups) {
                return;
            }

            this.$httpRequest.get('/admin_api/order/get_course_groups')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            for (let el of result) {
                                let cgInfo = {
                                    id: el.id,
                                    name: el.name
                                }
                                this.$set(this.courseGroups, `cg-${el.id}`, cgInfo);
                            }

                            this.isSetDataReady.getCourseGroups = true;
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        disableStartDate(date) {
            // disable小於今天以前的日期與大於有設定的結束時間
            return date < new Date().setHours(0, 0, 0, 0) ||
                    (this.bannerInfo.publishEnd && date.setHours(0, 0, 0, 0) > new Date(this.bannerInfo.publishEnd).setHours(0, 0, 0, 0));
        },
        disableStartTime(date) {
            // disable小於目前時間(時)以前的時間與大於有設定的結束時間(時)
            return date <= new Date() ||
                    (this.bannerInfo.publishEnd && date >= new Date(this.bannerInfo.publishEnd));
        },
        disableEndDate(date) {
            // disable小於今天以前的日期與小於有設定的開始時間
            return date < new Date().setHours(0, 0, 0, 0) ||
                    (this.bannerInfo.publishStart && date.setHours(0, 0, 0, 0) < new Date(this.bannerInfo.publishStart).setHours(0, 0, 0, 0));
        },
        disableEndTime(date) {
            // disable小於目前時間(時)以前的時間與小於有設定的開始時間(時)
            return date <= new Date() ||
                    (this.bannerInfo.publishStart && date <= new Date(this.bannerInfo.publishStart));
        },

        saveBanner() {
            let params = new FormData();

            let infoData = {};
            let apiUrl = '';

            // 新增橫幅
            if (!this.isUpdatingBanner) {
                infoData = {
                    name: this.bannerInfo.name,
                    resourceType: this.bannerInfo.linkedResource.type,  // 連結項目類型
                    isUpdateMaintain: this.bannerInfo.isUpdateMaintain,
                    startDate: this.$util.datetimeToUnixTimestamp(this.bannerInfo.publishStart),
                    endDate: this.bannerInfo.publishEnd ? this.$util.datetimeToUnixTimestamp(this.bannerInfo.publishEnd) : null
                }

                // 載具導流
                if (infoData.resourceType !== '0') {
                    infoData.isDeviceLink = this.bannerInfo.isDeviceLink;
                }

                // 連結項目為 '單一課程' 或 '系列課程' 時, 需帶 resourceId
                if (['2', '4'].includes(infoData.resourceType)) {
                    infoData.resourceId = this.bannerInfo.linkedResource.id;
                }
                // 連結項目為 '外部連結' 時, 需帶 resourceUrl
                if (infoData.resourceType === '3') {
                    infoData.resourceUrl = this.bannerInfo.linkedResource.url;
                }

                params.append('bannerInfo', JSON.stringify(infoData));

                apiUrl = '/admin_api/banner/create_banner';
            }
            // 編輯橫幅
            else {
                infoData = {
                    id: this.editingBannerInfo.id,
                    name: this.updatingInfo.name,
                    resourceType: this.updatingInfo.linkedResource && this.updatingInfo.linkedResource.type ? this.updatingInfo.linkedResource.type : null,
                    resourceId: this.updatingInfo.linkedResource && this.updatingInfo.linkedResource.id ? this.updatingInfo.linkedResource.id : null,
                    resourceUrl: this.updatingInfo.linkedResource && this.updatingInfo.linkedResource.url ? this.updatingInfo.linkedResource.url : null,
                    isDeviceLink: this.updatingInfo.isDeviceLink,
                    isUpdateMaintain: this.updatingInfo.isUpdateMaintain,
                    startDate: this.updatingInfo.publishStart ? this.$util.datetimeToUnixTimestamp(this.updatingInfo.publishStart) : null
                }
                infoData = this.$_.omitBy(this.$_.omit(infoData, ['endDate']), this.$_.isNil);

                // 結束時間
                if (this.$_.has(this.updatingInfo, 'publishEnd')) {
                    infoData.endDate = this.updatingInfo.publishEnd ? this.$util.datetimeToUnixTimestamp(this.updatingInfo.publishEnd) : null;
                }

                params.append('updateInfo', JSON.stringify(infoData));

                apiUrl = '/admin_api/banner/update_banner';
            }

            // 橫幅圖片檔案
            if (this.bannerInfo.image.file) {
                params.append('image', this.bannerInfo.image.file);
            }

            this.isPostingApi.saveBanner = true;

            this.$httpRequest.post(apiUrl, params)
                .then(response => {
                    this.isPostingApi.saveBanner = false;

                    if (response.data.state == 'OK') {
                        let savedItem = {
                            id: this.isUpdatingBanner ? this.editingBannerInfo.id : response.data.result,
                            name: this.bannerInfo.name,
                            image: this.bannerInfo.image.url,
                            publishStart: this.$util.datetimeToUnixTimestamp(this.bannerInfo.publishStart),
                            publishEnd: this.bannerInfo.publishEnd ? this.$util.datetimeToUnixTimestamp(this.bannerInfo.publishEnd) : null
                        }

                        // 新增狀態下, 需將新增項目加入至排程列表內
                        if (!this.isUpdatingBanner) {
                            this.newScheduledItem = savedItem;
                        }
                        // 編輯狀態下, 需更新上架/排程列表上的項目內容
                        else {
                            if (this.editingBannerInfo.state === 'published') {
                                this.editedPublishedItem = savedItem;
                            }
                            if (this.editingBannerInfo.state === 'scheduled') {
                                this.editedScheduledItem = savedItem;
                            }
                        }

                        this.$store.dispatch('common/setAlert', { status: 'success', msg: response.data.msg });

                        $('#editBannerDialogue').modal('hide');
                    }
                })
                .catch(error => {
                    this.isPostingApi.saveBanner = false;
                    console.error('Catched Error:', error);
                });
        }
    }
}
