var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container lms-container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"event-document"},[_c('h1',{staticClass:"document-title"},[_vm._v("Q & A")]),_c('ul',{staticClass:"document-section-list"},[_c('li',{staticClass:"section-list-item"},[_c('ul',{staticClass:"question-list"},[_c('li',{staticClass:"question-list-item"},[_c('div',{staticClass:"question-content"},[_c('h3',{staticClass:"question"},[_vm._v(" 我要怎麼報名參加活動？ ")]),_c('div',{staticClass:"answer"},[_vm._v(" A：您必須先註冊為加分吧會員，並登入後，點選右上角的「我要報名」即可參加活動。完成後會顯示文字「報名成功」。 "),_vm._m(0),_c('br'),_vm._m(1),_vm._v(" 本次活動參加對象為「花蓮縣的學校學生」，若您按了「我要參加」後出現下方文字，代表您的學校並非位於花蓮縣。很抱歉，同學不符合本次參加活動的資格，故也無法參加活動。若您是其餘縣市學生請至"),_c('router-link',{staticClass:"content-link",attrs:{"to":"/event/1"}},[_vm._v("＿此連結＿")]),_vm._v("報名。 "),_vm._m(2)],1)])]),_vm._m(3),_vm._m(4),_vm._m(5)])])]),_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"attached-picture-list"},[_c('li',{staticClass:"attached-picture-list-item"},[_c('figure',{staticClass:"attached-picture-figure"},[_c('img',{staticClass:"attached-picture",attrs:{"src":"https://premium-resource.learnmode.net/upload/event/2/QA/s_01_01.png"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"attached-picture-list"},[_c('li',{staticClass:"attached-picture-list-item"},[_c('figure',{staticClass:"attached-picture-figure"},[_c('img',{staticClass:"attached-picture",attrs:{"src":"https://premium-resource.learnmode.net/upload/event/2/QA/s_01_02.png"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"attached-picture-list"},[_c('li',{staticClass:"attached-picture-list-item"},[_c('figure',{staticClass:"attached-picture-figure"},[_c('img',{staticClass:"attached-picture",attrs:{"src":"https://premium-resource.learnmode.net/upload/event/2/QA/s_01_03.png"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"question-list-item"},[_c('div',{staticClass:"question-content"},[_c('h3',{staticClass:"question"},[_vm._v(" 為什麼登入後無法報名？ ")]),_c('div',{staticClass:"answer"},[_vm._v(" A：請先確認是帳號或硬體的問題，解決方法如下： "),_c('ol',{staticClass:"second-layer-decimal-list"},[_c('li',{staticClass:"second-layer-list-item"},[_vm._v(" 帳號確認：請先確認您登入的帳號是使用教育部或花蓮縣教育雲端帳號登入，而非個人email或社群綁定帳號。 ")]),_c('li',{staticClass:"second-layer-list-item"},[_vm._v(" 硬體設備：請使用桌上型電腦、筆電、平板進行報名，手機不適用。 ")])]),_c('ul',{staticClass:"attached-picture-list"},[_c('li',{staticClass:"attached-picture-list-item"},[_c('figure',{staticClass:"attached-picture-figure"},[_c('figcaption',{staticClass:"attached-picture-description"},[_vm._v(" 下圖為選擇使用教育部或花蓮縣教育雲端帳號登入 ")]),_c('img',{staticClass:"attached-picture",attrs:{"src":"https://premium-resource.learnmode.net/upload/event/2/QA/s_02_01.png"}})])]),_c('li',{staticClass:"attached-picture-list-item"},[_c('figure',{staticClass:"attached-picture-figure"},[_c('figcaption',{staticClass:"attached-picture-description"},[_vm._v(" 下圖為選擇花蓮縣示意 ")]),_c('img',{staticClass:"attached-picture",attrs:{"src":"https://premium-resource.learnmode.net/upload/event/2/QA/s_02_02.png"}})])]),_c('li',{staticClass:"attached-picture-list-item"},[_c('figure',{staticClass:"attached-picture-figure"},[_c('figcaption',{staticClass:"attached-picture-description"},[_vm._v(" 下圖為登入示意 ")]),_c('img',{staticClass:"attached-picture",attrs:{"src":"https://premium-resource.learnmode.net/upload/event/2/QA/s_02_03.png"}})])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"question-list-item"},[_c('div',{staticClass:"question-content"},[_c('h3',{staticClass:"question"},[_vm._v(" 我報名完成後，要怎麼進入比賽？如何知道自己文章閱讀進度？ ")]),_c('div',{staticClass:"answer"},[_c('ol',{staticClass:"second-layer-decimal-list"},[_c('li',{staticClass:"second-layer-list-item"},[_vm._v(" 請點選個人頭像，再點擊下拉選單的「學習任務」，即出現本次競賽被分派每週10篇的學習任務。 "),_c('ul',{staticClass:"attached-picture-list"},[_c('li',{staticClass:"attached-picture-list-item"},[_c('figure',{staticClass:"attached-picture-figure"},[_c('img',{staticClass:"attached-picture",attrs:{"src":"https://premium-resource.learnmode.net/upload/event/2/QA/s_03_01.png"}})])])])]),_c('li',{staticClass:"second-layer-list-item"},[_vm._v(" 點入後，會顯示每篇文章的閱讀狀況，若已完成的閱讀會顯示綠色「完成」，也代表獲得積分。 "),_c('ul',{staticClass:"attached-picture-list"},[_c('li',{staticClass:"attached-picture-list-item"},[_c('figure',{staticClass:"attached-picture-figure"},[_c('img',{staticClass:"attached-picture",attrs:{"src":"https://premium-resource.learnmode.net/upload/event/2/QA/s_03_02.png"}})])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"question-list-item"},[_c('div',{staticClass:"question-content"},[_c('h3',{staticClass:"question"},[_vm._v(" 如何知道自己的成績與排名？ ")]),_c('div',{staticClass:"answer"},[_vm._v(" A：可點選上方的「排行榜」會呈現總排行與個人排行。 "),_c('ul',{staticClass:"attached-picture-list"},[_c('li',{staticClass:"attached-picture-list-item"},[_c('figure',{staticClass:"attached-picture-figure"},[_c('figcaption',{staticClass:"attached-picture-description"},[_vm._v(" 下圖為排行榜示意圖 ")]),_c('img',{staticClass:"attached-picture",attrs:{"src":"https://premium-resource.learnmode.net/upload/event/2/QA/s_04.png"}})])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"document-footer"},[_c('div',[_vm._v("若您還有其他相關問題，請於周一~週五 10:00~18:00 來電或寫信詢問。")]),_c('div',[_vm._v("聯絡電話：02-22185452 #867748 李宗翰先生、#867715 黃芳瑤小姐")]),_c('div',[_vm._v("電子郵件："),_c('a',{staticClass:"content-link",attrs:{"href":"mailto:jeremylee@via.com.tw"}},[_vm._v("jeremylee@via.com.tw")]),_vm._v("、"),_c('a',{staticClass:"content-link",attrs:{"href":"mailto:erinhuang@via.com.tw"}},[_vm._v("erinhuang@via.com.tw")])])])
}]

export { render, staticRenderFns }