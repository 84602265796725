export default {
    props: {
        errorMsg: {
            type: String,
            default: ''
        }
    },
    computed: {
        msg() {
            return this.errorMsg || atob(this.$route.query.authError);
        }
    }
}
