import { render, staticRenderFns } from "./auth_error.vue?vue&type=template&id=c58f6596&scoped=true&"
import script from "@/assets/js/components/account/auth_error.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/account/auth_error.js?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/account/auth_error.scss?vue&type=style&index=0&id=c58f6596&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c58f6596",
  null
  
)

export default component.exports