// components
const TableList = resolve => require(["@/components/admin/order/_table_list.vue"], resolve)

export default {
    props: ['activePage', 'userStateList'],
    components: {
        TableList
    },
    data: function() {
        return {
            allOrderIds: [],
            searchText: '',
            renderTableListCount: 0,

            isGettingApi: {
                getOrderIdsByState: false  // 取得使用者 ids
            }
        }
    },
    watch: {
        isClickSidebarLink(newValue) {
            if (newValue) {
                this.getOrderIdsByState();
            }
            this.isClickSidebarLink = false;
        }
    },
    computed: {
        isClickSidebarLink: {
            get() {
                return this.$store.state.adminOrder.isClickSidebarLink;
            },
            set(value) {
                this.$store.commit('adminOrder/setIsClickSidebarLink', value);
            }
        }
    },
    created: function() {
        this.isClickSidebarLink = false;
        this.getOrderIdsByState();
    },
    methods: {
        getOrderIdsByState() {
            if (this.isGettingApi.getOrderIdsByState) {
                return;
            }

            let params = {
                    status: this.$route.params.subPage
                };

            this.allOrderIds = [];
            this.renderTableListCount++;

            this.isGettingApi.getOrderIdsByState = true;

            this.$httpRequest.get('/admin_api/order/get_list_ids', params)
                .then(response => {
                    this.isGettingApi.getOrderIdsByState = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allOrderIds = result;
                            this.renderTableListCount++;
                        }
                    }
                })
                .catch(function (error) {
                    console.log('catched error: ' + error);
                });
        },
        getOrderIdsBySearchText() {
            let params = {
                    keyword: this.searchText,
                    status: this.$route.params.subPage
                }

            if (!this.searchText) {
                this.getOrderIdsByState();
                return;
            }

            this.allOrderIds = [];

            this.$httpRequest.get('/admin_api/order/get_search_list_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allOrderIds = result;
                            this.renderTableListCount++;
                        }
                    }
                })
                .catch(function (error) {
                    console.log('catched error: ' + error);
                });
        },
        getOrderListReportFile() {
            let params = {
                status: this.$route.params.subPage
            };
            this.$httpRequest.get('/admin_api/report/get_order_list', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let url = response.data.result;

                        let fileLink = document.createElement('a');
                        fileLink.href = url;
                        fileLink.setAttribute('download', 'order_list_YYYYMMDD.csv');
                        fileLink.click();
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        }
    }
}
