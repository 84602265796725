// common
const Dialogue = resolve => require(["@/components/common/dialogue_client.vue"], resolve)
const MobileUnsupported = resolve => require(["@/components/common/mobile_unsupported.vue"], resolve)
// components
const MissionList = resolve => require(["@/components/user/mission/_mission_list.vue"], resolve)

export default {
    components: {
        Dialogue,
        MobileUnsupported,
        MissionList,
    },
    data: function() {
        return {
            createDialogueId: '',
            isGettingGroupCount: false,  // 正在取得使用者自建班級數量

            periodOptions: {
                '0': '所有時間',
                '1': '一週內',
                '2': '一個月內',
                '3': '三個月內',
                '4': '半年內',
                '5': '一年內'
            },
            teacherOptions: {
                't-0': '所有教師'
            },
            groupOptions: {
                'g-0': '所有班級'
            },
            statusOptions: {
                '0': '所有狀態',
                'unstart': '未開始',
                'ongoing': '進行中',
                'complete': '完成'
            },

            selectedOptions: {
                period: '',
                teacher: '',
                group: '',
                status: ''
            },
            concatenatedOpts: '',
            filterListCount: 0,
            isShowFilterList: false,
            isFilteringList: false,

            isSetDataReady: {
                getOptions: false,  // 取得篩選老師與班級的選項列表
            },
        }
    },
    watch: {
        '$route.query.m'(newValue) {
            newValue && this.resetSelectedOptions();
        },
        filterListCount() {
            this.concatenatedOpts = Object.values(this.selectedOptions).join('');
        },
        selectedList() {
            this.$store.commit('common/setHeaderActiveLink', this.headerLinkName);
            this.resetSelectedOptions();
            this.isSetDataReady.getOptions = false;
        },
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        listOptions() {
            let aryOpts = [];
            aryOpts = [
                {
                    value: 'created',
                    text: '創建任務',
                    isShow: this.userInfo.permission !== 'user',
                },
                {
                    value: 'assigned',
                    text: '教師指派',
                    isShow: true,
                },
                {
                    value: 'event',
                    text: '活動任務',
                    isShow: this.userInfo.permission === 'user',
                },
            ];

            // array -> object
            let objOpts = {};
            for (let el of aryOpts) {
                if (!el.isShow) continue;
                objOpts[el.value] = el.text;
            }

            return objOpts;
        },
        selectedList() {
            let validOpts = Object.keys(this.listOptions);
            let query = {...this.$route.query};
            if (query.list && !validOpts.includes(query.list)) {
                delete query.list;
                this.$router.push({ 'query': query });
            }

            let list = this.$route.query.list || validOpts[0];

            return list;
        },
        renderListKey() {
            return `${this.selectedList}|${this.filterListCount}|${this.$route.query.m}`;
        },
        headerLinkName() {
            if (this.$route.query.list === 'event') {
                return 'eventMission';
            } else {
                return 'userMission';
            }
        },
    },
    created: function() {
        this.$store.commit('common/setHeaderActiveLink', this.headerLinkName);
    },
    methods: {
        addMission() {
            if (this.createDialogueId) {
                $(this.createDialogueId).modal('show');
                return;
            }

            this.isGettingGroupCount = true;

            this.$httpRequest.get('/api/group/get_group_count')
                .then(response => {
                    this.isGettingGroupCount = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.createDialogueId = +result ? '#addMissionDialogue' : '#createPromptDialogue';
                            $(this.createDialogueId).modal('show');
                        }
                    }
                })
                .catch(error => {
                    this.isGettingGroupCount = false;
                    console.error('Catched Error:', error);
                });
        },

        getOptions() {
            if (this.selectedList !== 'assigned' || this.isSetDataReady.getOptions) {
                return;
            }

            this.isShowFilterList = true;

            this.$httpRequest.get('/api/mission/get_assigned_mission_selections')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            for (let u of result.teachers) {
                                this.$set(this.teacherOptions, `t-${u.id}`, u.name);
                            }

                            for (let g of result.groups) {
                                this.$set(this.groupOptions, `g-${g.id}`, g.name);
                            }

                            this.isSetDataReady.getOptions = true;
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        filterList() {
            this.filterListCount++;
            this.isFilteringList = true;

            window.scrollTo(0, 0);

            let query = {...this.$route.query};
            if (query.m) {
                delete query.m;
                this.$router.push({ 'query': query });
            }
        },
        resetSelectedOptions() {
            Object.keys(this.selectedOptions).forEach(key => this.selectedOptions[key] = '');
            this.concatenatedOpts && this.filterListCount++;
        },
    }
}
