// components
const UserCouponList = resolve => require(["@/components/user/coupons/_user_coupon_list.vue"], resolve)

export default {
    components: {
        UserCouponList
    },
    data: function() {
        return {
            allUserCouponIds: [],
            inputCouponCode: '',
            newCouponList: [],  // 新領取的優惠券列表
            deletingUserCouponIds: [],  // 準備刪除的優惠券 ucIds (遇到重複狀況時)
            isInputCodeError: false,  // 優惠代碼是否輸入錯誤
            isGettingNewCoupon: false,  // 是否正在取得新優惠券

            isSetDataReady: {
                getUserCouponIds: false,  // 取得個人優惠券的 ids
            }
        }
    },
    watch: {
        inputCouponCode() {
            this.isInputCodeError = false;
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        }
    },
    created: function() {
        this.$store.commit('common/setHeaderActiveLink', 'userCoupons');

        this.getUserCouponIds();
    },
    methods: {
        async getUserCouponIds() {
            let payload = {
                type: 'user',
                courseIds: []
            }

            this.allUserCouponIds = await this.$store.dispatch('user/getUserCouponIds', payload);
            this.isSetDataReady.getUserCouponIds = true;
        },
        async getNewCoupon() {
            $('.lms-btn.outline').blur();

            if (!this.inputCouponCode) {
                return;
            }

            let payload = {
                code: this.inputCouponCode,
                courseIds: []
            }

            this.isGettingNewCoupon = true;

            try {
                let newCoupons = await this.$store.dispatch('user/getNewCoupons', payload);

                this.deletingUserCouponIds = [];
                let concatingUserCouponIds = [];

                for (let newCoupon of newCoupons) {
                    // 使用者已領取過目前輸入的優惠券
                    if (newCoupon.isExist) {
                        // 從原 userCouponIds 陣列中, 刪除該 userCouponId element
                        this.allUserCouponIds = this.$_.remove(this.allUserCouponIds, el => {
                            return el !== newCoupon.id;
                        });
                        // 準備刪除的 ucIds
                        this.deletingUserCouponIds.push(newCoupon.id);
                    }

                    concatingUserCouponIds.push(newCoupon.id);
                }

                // 把新增的 ucIds 至 userCouponIds 陣列前面
                this.allUserCouponIds = concatingUserCouponIds.concat(this.allUserCouponIds);

                // 取得優惠券資訊
                let newCouponList = await this.$store.dispatch('user/getCouponsInfo', { userCouponIds: concatingUserCouponIds });
                // 設置列表新增的項目資訊
                this.newCouponList = newCouponList;

                this.inputCouponCode = '';
                this.isGettingNewCoupon = false;

                // 卷軸移動回到畫面最上方
                $('html, body').animate({
                    scrollTop: 0
                }, 500);
            } catch (error) {
                this.isGettingNewCoupon = false;
                this.isInputCodeError = true;
                console.error('Catched Error:', error);
            }
        },
        clearInputCouponCode() {
            this.inputCouponCode = '';
            $('#couponCodeInput').focus();
        }
    }
}
