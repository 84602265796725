// common
const Dialogue = resolve => require(["@/components/common/dialogue_client.vue"], resolve)

export default {
    components: {
        Dialogue,
    },
    computed: {
        recognizedScoreList() {
            let list = [];
            list = [
                {
                    score: 5,
                    text: "發音準確性及流暢度完全正確。"
                },
                {
                    score: 4,
                    text: "發音準確性及流暢度有些微瑕疵。"
                },
                {
                    score: 3,
                    text: "發音準確性及流暢度有少部分錯誤。"
                },
                {
                    score: 2,
                    text: "發音準確性及流暢度常有錯誤。"
                },
                {
                    score: 1,
                    text: "發音準確性及流暢度錯誤甚多。"
                },
                {
                    score: 0,
                    text: "未答或等同未答。"
                }
            ]
            return list;
        },
    },
}
