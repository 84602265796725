import InfiniteScroll from 'vue-infinite-scroll'

export default {
    props: {
        defaultItem: {
            required: true,
            type: String
        },
        selectedGroupId: {
            required: true,
            type: String
        }
    },
    directives: {
        InfiniteScroll
    },
    data: function() {
        return {
            allGIds: [],  // g: group
            groupList: [],

            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 10,  // 一次載入幾筆
            isGetAllList: false  // 是否全部載入完畢
        }
    },
    created: function() {
        this.getGroupIds();
    },
    methods: {
        getGroupIds() {
            this.$httpRequest.get('/api/group/get_group_list_ids')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allGIds = result;

                            if (!this.allGIds.length) {
                                this.busy = false;
                                this.isGetAllList = true;
                            }

                            this.loadMore();
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        async loadMore() {
            if (this.isGetAllList) {
                return;
            }

            this.busy = true;
            await this.getGroupList();
            this.busy = false;
        },
        getGroupList() {
            return new Promise((resolve, reject) => {
                let gIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let gId = this.allGIds[this.loadingIndex];
                    if (!gId) {
                        this.isGetAllList = true;
                        break;
                    }
                    gIds.push(gId);
                    this.loadingIndex++;
                }

                if (!gIds.length) {
                    resolve();
                    return;
                }

                let params = {
                    groupIds: gIds
                };

                this.$httpRequest.post('/api/group/get_group_list_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                for (let gId of params.groupIds) {
                                    let g = result[gId];

                                    if (!g) {
                                        continue;
                                    }

                                    // 非自己建立的群組或群組人數為空則不需列出
                                    if (g.admin === '0' || !+g.count) {
                                        continue;
                                    }

                                    this.groupList.push({
                                        id: gId,
                                        name: g.name
                                    });
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        }
    }
}
