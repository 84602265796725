// common
import Dialogue from "@/components/common/dialogue.vue"
// components
const UserTableList = resolve => require(["@/components/admin/article/edit/_user_table_list.vue"], resolve)

export default {
    props: ['articleBasicInfo'],
    components: {
        Dialogue,
        UserTableList
    },
    data: function() {
        return {
            activeTab: 'editors',

            editorList: [],
            oldEditorIds: [],
            selectedEditorIndex: null,
            deletedUserIds: [],

            // add editor dialogue
            allUserIds: [],
            searchText: '',
            renderTableListKey: 0,

            isSetDataReady: {
                getEditorList: false  // 取得課程編輯者列表資料
            }
        }
    },
    computed: {
        editEditorsTabList() {
            let tabs = [];
            tabs = [
                {
                    value: 'editors',
                    text: '責任編輯'
                }
            ]
            return tabs;
        },
        editorsTableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "id",
                    itemText: "ID",
                    ownClass: "article-editor-id",
                    isShow: true
                },
                {
                    itemName: "name",
                    itemText: "姓名",
                    ownClass: "article-editor-name",
                    isShow: true
                },
                {
                    itemName: "permission",
                    itemText: "權限",
                    ownClass: "article-editor-permission",
                    isShow: true
                },
                {
                    itemName: "advanced",
                    itemText: "進階",
                    ownClass: "article-editor-advanced",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        selectedUsersTableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "id",
                    itemText: "ID",
                    ownClass: "selected-users-id",
                    isShow: true
                },
                {
                    itemName: "name",
                    itemText: "姓名",
                    ownClass: "selected-users-name",
                    isShow: true
                },
                {
                    itemName: "permission",
                    itemText: "權限",
                    ownClass: "selected-users-permission",
                    isShow: true
                },
                {
                    itemName: "delete",
                    itemText: "移除",
                    ownClass: "selected-users-delete",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        currentEditorIds() {
            let list = [], idsAry = [];
            list = this.$_.concat(this.editorList, this.newEditorList);
            list.forEach(el => idsAry.push(el.id));
            return idsAry;
        },
        isReachEditorLimitCount() {
            return this.currentEditorIds.length >= 10;
        },
        currentEditorAdminCount() {
            let obj = this.$_.countBy(this.editorList, rec => {
                return rec.permission == 'admin';
            });
            return obj.true ? obj.true : 0;
        },
        isDisableDeleteEditor() {
            // 編輯中: 編輯者列表不能少於一人
            // 送審之後的狀態: 編輯者列表至少需要一個admin
            return permission => (this.articleBasicInfo.state == '0' && this.editorList.length == 1) || (this.articleBasicInfo.state != '0' && permission == 'admin' && this.currentEditorAdminCount == 1);
        },
        updateArticleData() {
            if (!this.isSetDataReady.getEditorList) {
                return;
            }

            let updateData = {};

            // 負責編輯 - 責任編輯
            // 判斷編輯者列表是否有做新增或刪除項目
            let tempEditorIds = [];
            this.editorList.forEach(el => tempEditorIds.push(el.id));
            let diffUserIds = this.$_.difference(tempEditorIds, this.oldEditorIds);

            // 有新增的編輯者
            if (diffUserIds.length) {
                updateData.addIds = diffUserIds;
            }
            // 有刪除的編輯者
            if (this.deletedUserIds.length) {
                updateData.deleteIds = this.deletedUserIds;
            }

            updateData.saveType = 'editors';  // 指定儲存類型

            return updateData;
        },
        isEditable() {
            // 是否可以新增或刪除課程編輯者, 條件如下:
            // 1. 課程狀態為"編輯中"
            // 2. 使用者權限為 admin 時, 不管課程狀態為何皆可編輯以上項目
            return this.articleBasicInfo.state == '0' ||
                    this.$store.state.common.userInfo.permission == 'admin';
        },
        newEditorList: {
            get() {
                return this.$store.state.adminArticle.newEditorList;
            },
            set(value) {
                this.$store.commit('adminArticle/setNewEditorList', value);
            }
        }
    },
    created: function() {
        this.initialize();
    },
    mounted: function() {
        // 彈窗打開後直接 focus 輸入框
        $('#addEditorDialogue').on('shown.bs.modal', () => {  // 加入帳號
            $('#searchUsersInput').focus();
        });
    },
    methods: {
        initialize() {
            let refreshEditorList = () => {
                this.$parent.isFinishUpdatingData = false;
                this.isSetDataReady.getEditorList = false;
                this.oldEditorIds = [];
                this.deletedUserIds = [];
                this.allUserIds = [];  // dialogue

                this.getEditorList()
                    .then(() => {
                        this.isSetDataReady.getEditorList = true;

                        // 監聽編輯內容是否更動
                        this.$watch('updateArticleData', newValue => {
                            this.$store.commit('adminArticle/setUpdateArticleData', newValue);
                            this.$store.commit('adminArticle/setIsShowNotSaveArticlePrompt', !this.$_.isEmpty(this.$_.omit(newValue, ['saveType'])));
                        });
                    })
                    .catch(error => console.log('catched error: ' + error));
            }

            refreshEditorList();

            // 當成功更新課程編輯者後, 則重新取得編輯者列表
            this.$watch('$parent.isFinishUpdatingData', newValue => {
                if (newValue) {
                    // 若使用者在編輯者列表刪除自己, 則需導向"課程列表"頁面
                    if (this.$store.state.adminArticle.isSelfDeletedFromEditors) {
                        this.$store.commit('adminArticle/setIsShowNotSaveArticlePrompt', false);
                        this.$router.push('/admin/article/list/all');
                    } else {
                        refreshEditorList();
                    }
                }
            });
        },

        getEditorList() {
            if (!this.$route.params.id) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    articleId: this.$route.params.id
                }

                this.editorList = [];

                this.$httpRequest.get('/admin_api/article/get_article_editors', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                result.forEach(el => {
                                    this.editorList.push({
                                        id: el.id,
                                        name: el.name,
                                        permission: el.permission
                                    });
                                });

                                // 暫存原本的分類 ids, 以用來判斷是否enable儲存按鈕
                                this.editorList.forEach(el => this.oldEditorIds.push(el.id));

                                resolve();
                            } else {
                                reject("admin/article/edit/editors.js: 'getEditorList' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("admin/article/edit/editors.js: 'getEditorList' get error");
                        }
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                        reject("admin/article/edit/editors.js: 'getEditorList' get error");
                    });
            });
        },
        showAddEditorDialogue() {
            if (this.editorList.length >= 10 || !this.isEditable) {
                return;
            }

            this.newEditorList = [];
            this.allUserIds = [];
            this.searchText = '';
            this.renderTableListKey++;
            $('#addEditorDialogue').modal('show');
        },
        getUserIdsBySearchText() {
            if (!this.searchText) {
                return;
            }

            let params = {
                permission: ['admin', 'teacher'],
                keyword: this.searchText
            }

            this.allUserIds = [];

            this.$httpRequest.post('/admin_api/user/get_search_list_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allUserIds = result;
                            this.renderTableListKey++;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        deleteUserFromEditorList(index) {
            $('.lms-btn.icon-circle').blur();

            this.newEditorList = this.$_.remove(this.newEditorList, (el, i) => {
                return i !== index;
            });
        },
        addEditor() {
            if (!this.newEditorList.length) {
                return;
            }

            // 當有刪除編輯者時, 如果有再透過"加入帳號"加回使用者, 需從 deletedUserIds 陣列刪除該使用者 id
            if (this.deletedUserIds.length) {
                let tempAddUserIds = [];
                this.newEditorList.forEach(el => tempAddUserIds.push(el.id));
                this.deletedUserIds = this.$_.remove(this.deletedUserIds, el => {
                    return !this.$_.intersection(this.deletedUserIds, tempAddUserIds).includes(el);
                });
            }

            this.editorList = this.$_.concat(this.editorList, this.newEditorList);
            $('#addEditorDialogue').modal('hide');
        },
        showDeleteEditorDialogue(index) {
            if (!this.isEditable) {
                return;
            }

            this.selectedEditorIndex = index;
            $('#deleteEditorDialogue').modal('show');
        },
        deleteEditor() {
            this.editorList = this.$_.remove(this.editorList, (el, i) => {
                if (i === this.selectedEditorIndex && el.isSelected === undefined) {  // 欲刪除的編輯者為原本存在的編輯者
                    this.deletedUserIds.push(el.id);
                }
                return i !== this.selectedEditorIndex;
            });
            $('#deleteEditorDialogue').modal('hide');
        }
    }
}
