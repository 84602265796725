import InfiniteScroll from 'vue-infinite-scroll'

export default {
    props: {
        mId: {
            required: true,
            type: String
        },
        status: {
            required: true,
            type: String
        }
    },
    directives: {
        InfiniteScroll
    },
    data: function() {
        return {
            allUIds: [],  // u: user
            userList: [],

            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個 index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false  // 是否全部載入完畢
        }
    },
    created: function() {
        this.getUserIds();
    },
    methods: {
        getUserIds() {
            let params = {
                missionId: this.mId,
                status: this.status
            };

            this.$httpRequest.get('/api/mission/get_status_member_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            let statusCount = {
                                unstart: result.status_count.unstart_count,
                                ongoing: result.status_count.ongoing_count,
                                complete: result.status_count.complete_count
                            }
                            this.$emit('statusCountUpdate', statusCount);

                            this.allUIds = result.user_ids;

                            if (!this.allUIds.length) {
                                this.busy = false;
                                this.isGetAllList = true;
                            }

                            this.loadMore();
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        async loadMore() {
            if (this.isGetAllList) {
                return;
            }

            this.busy = true;
            await this.getUserList();
            this.busy = false;
        },
        getUserList() {
            return new Promise((resolve, reject) => {
                let uIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let uId = this.allUIds[this.loadingIndex];
                    if (!uId) {
                        this.isGetAllList = true;
                        break;
                    }
                    uIds.push(uId);
                    this.loadingIndex++;
                }

                if (!uIds.length) {
                    resolve();
                    return;
                }

                let params = {
                    userIds: uIds
                };

                this.$httpRequest.post('/api/mission/get_status_member_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                for (let uId of params.userIds) {
                                    let u = result[uId];

                                    if (!u) {
                                        continue;
                                    }

                                    this.userList.push({
                                        id: uId,
                                        class: u.class,
                                        classNo: u.class_no,
                                        name: u.name,
                                        account: u.account
                                    });
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        }
    }
}
