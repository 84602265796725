export default {
    props: ['courseInfo'],
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        courseUtil() {
            return this.$store.state.course.courseUtil;
        }
    }
}
